.content, .photo {
  display: inline-block;
}

.photo {
  float: left;
  width: 30em;
  max-width: 100%;
  margin-right: 2em;
}

.photo img {
  border-radius: .5em;
  width: 100%;
}

.content img.freecbt-icon, .content img.swarmsim-icon {
  border: 0;
  height: .8em;
  margin: 0;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background-color: #000;
  }

  a {
    color: #0ff;
  }

  a:visited {
    color: pink;
  }
}

.social-links a {
  font-size: 200%;
  text-decoration: none;
}
/*# sourceMappingURL=index.62d3d4d7.css.map */

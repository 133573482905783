.content, .photo {
  display: inline-block;
}
.photo {
  float: left;
  width: 30em;
  max-width: 100%;
  margin-right: 2em;
}
.photo img {
  width: 100%;
  border-radius: 0.5em;
}
.content img.freecbt-icon,
.content img.swarmsim-icon {
  height: 0.8em;
  margin: 0; padding: 0; border: 0;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
    color: white;
  }
  a {
    color: cyan;
  }
  a:visited {
    color: pink;
  }
}

.social-links a {
  font-size: 200%;
  text-decoration: none;
}